@import "src/styles/helpers";

.service {
  @include pageHeaderPadding;
}

.hero {
  display: grid;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 64px;

  @include media(tablet) {
    padding-top: 140px;
    padding-bottom: 96px;
  }

  @include media(desktopAll) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 465px);
    text-align: left;
    row-gap: 160px;
    padding-bottom: 113px;
  }

  @include media(desktopSmall) {
    padding-top: 133px;
    column-gap: 75px;
  }

  @include media(desktop) {
    column-gap: 120px;
    padding-top: 133px;
  }

  @include media(desktopBig) {
    padding-top: 133px;
    column-gap: 140px;
  }

  @include media(desktopHuge) {
    padding-top: 135px;
    column-gap: 160px;
  }

  .title {
    margin-bottom: 28px;

    @include media(portrait) {
      margin-bottom: 32px;
    }

    @include media(landscape) {
      margin-bottom: 56px;
    }

    mark {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }
  }

  &__text {
    margin-bottom: 28px;
    font-size: 14px;
    line-height: 2;
    font-weight: 300;

    @include media(tablet) {
      margin-bottom: 32px;
    }

    @include media(desktopAll) {
      margin-top: 7px;
      margin-bottom: 40px;
    }
  }

  &__button {
    width: 100%;
    margin: 0 auto;

    @include media(desktopAll) {
      margin-right: 0;
    }
  }

  &__image {
    margin-top: 60px;

    @include media(tablet) {
      margin-top: 80px;
    }

    @include media(desktopAll) {
      margin-top: 0;
      grid-column: 1 / 3;
    }
  }
}

.serviceCard {
  text-align: center;
  padding: 56px 0;

  @include media(tablet) {
    margin-bottom: 12px;
    display: grid;
    grid-template-columns: 1fr 206px;
    align-items: center;
    text-align: left;
  }

  @include media(desktopAll) {
    margin-bottom: 12px;
    display: grid;
    grid-template-columns: 600px 206px;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    gap: var(--margin-xxl);
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid;
    @include setProperty(border-bottom-color, var(--dark-line-low-accent), var(--light-line-low-accent));
  }

  &__title {
    font-family: 'KharkivTone', sans-serif;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: var(--margin-md);

    @include media(tablet) {
      font-size: 24px;
      line-height: 32px;
    }

    @include media(desktop) {
      font-size: 24px;
      line-height: 32px;
    }

    @include media(desktopAll) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 2;
    font-weight: 300;
  }

  &__button {
    display: none !important;
  }

  &__visuals {
    margin: var(--margin-xxl) auto 0;
    position: relative;
    height: 134px;
    width: 206px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media(tablet) {
      margin-top: 0;
    }

    @include media(desktopAll) {
      margin-top: 0;
    }

    .visualsItem {
      position: absolute;
      transition: all 0.3s ease;
      pointer-events: none;

      img {
        width: 100%;
      }

      &_big {
        width: 134px;
      }

      &_medium {
        width: 80px;
      }

      &_small {
        width: 26px;
      }
    }

    &_1 {

      .visualsItem {

        &_big {
          right: 0;
          top: 0;
        }

        &_medium {
          left: 7px;
          top: 0;
        }

        &_small {
          left: 0;
          top: 0;
        }
      }
    }

    &_2 {

      .visualsItem {

        &_big {
          top: 0;
          left: 10px;
        }

        &_medium {
          right: 0;
          top: 0;
        }

        &_small {
          left: 0;
          top: 0;
        }
      }
    }

    &_3 {

      .visualsItem {
        top: 50%;
        transform: translateY(-50%);

        &_big {
          right: -10px;
        }

        &_medium {
          left: 24px;
        }

        &_small {
          left: 10px;
        }
      }
    }

    &_4 {

      .visualsItem {

        &_big {
          top: 0;
          right: 0;
        }

        &_medium {
          top: 0;
          left: 0;
        }

        &_small {
          top: 0;
          left: 60px;
        }
      }
    }
  }

  &.active {

    .serviceCard__visuals {

      .visualsItem {

        &_big {
          width: 189px;
        }
      }

      &_1 {

        .visualsItem {

          &_big {
            right: -50%;
            top: 50%;
            transform: translateY(-50%);
          }

          &_medium {
            top: 70%;
            left: 80%;
          }

          &_small {
            left: -10px;
            top: 50%;
            transform: translateY(-40%);
          }
        }
      }

      &_2 {

        .visualsItem {

          &_big {
            top: 50%;
            transform: translateY(-50%);
            left: 50%;
          }

          &_medium {
            right: -100px;
            top: 50%;
            transform: translateY(-50%);
          }

          &_small {
            left: -10px;
            top: 30%;
          }
        }
      }

      &_3 {

        .visualsItem {

          &_big {
            right: -60px;
          }

          &_medium {
            left: 95%;
          }

          &_small {
            left: 127%;
          }
        }
      }

      &_4 {

        .visualsItem {

          &_big {
            right: -110px;
          }

          &_medium {
            left: 120%;
            top: 130px;
          }

          &_small {
            left: -15px;
          }
        }
      }
    }

    .serviceCard {

      &__button {
        display: flex !important;
      }
    }
  }

  &.muted {
    opacity: 0.4;
  }
}

.services {
  padding-top: var(--margin-xxl);
  padding-bottom: var(--margin-xxl);

  @include media(tablet) {
    padding-top: 104px;
    padding-bottom: 64px;
  }

  @include media(desktopAll) {
    margin: 0 auto;
    padding-bottom: 104px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 1040px;
  }

  &__title {
    margin-bottom: 0;

    @include media(mobile) {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 56px;
    }

    @include media(portrait) {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 36px;
    }

    @include media(landscape) {
      margin-bottom: 92px;
      font-size: 40px;
      line-height: 48px;
    }

    @include media(desktopAll) {
      margin-bottom: 92px;
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__button {
    width: 100% !important;
    margin-top: 12px;

    @include media(desktopAll) {
      display: none !important;
    }
  }
}

.team {
  padding: 56px 0;

  &__header {
    text-align: center;
    margin-bottom: var(--margin-xxl);

    @include media(portrait) {
      max-width: 368px;
      margin: 0 auto var(--margin-xxl);
    }
  }

  &__title {
    margin-bottom: var(--margin-md);
  }

  &__subtitle {
    line-height: 2;
  }

  &__members {
    padding: 0 var(--margin-md) var(--margin-xl);
    display: grid;
    gap: 60px;

    @include media(portrait) {
      grid-template-columns: repeat(2, 324px);
      padding: 0 40px var(--margin-xl);
      justify-content: center;
      gap: 0 var(--margin-lg);
    }

    @include media(landscape) {
      grid-template-columns: repeat(4, 1fr);
      gap: var(--margin-md);
      padding: 0 0 var(--margin-xl);
    }

    @include media(desktopAll) {
      grid-template-columns: repeat(4, 1fr);
      gap: var(--margin-xl);
      padding: 0 0 var(--margin-xl);
    }

    @include media(desktopBig) {
      gap: var(--margin-xl);
      max-width: 1440px;
      margin: 0 auto;
    }

    @include media(desktopHuge) {
      gap: var(--margin-xl);
      max-width: 1440px;
      margin: 0 auto;
    }
  }

  &__cards {
    padding: 0;
    display: grid;
    gap: 24px 0;

    @include media(landscape) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-auto-rows: 1fr;
    }

    @include media(desktopAll) {
      padding-top: 40px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-auto-rows: 1fr;
    }
  }

  &__card {
    padding: 40px 0;
    text-align: center;
    font-size: 14px;
    line-height: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media(tablet) {
      padding: 40px 56px;
    }

    @include media(desktopAll) {
      padding: 56px 56px 40px;
      font-size: 16px;
    }

    &__image {
      margin-bottom: var(--margin-sm);

      @include media(desktopAll) {
        margin-bottom: var(--margin-xs);
      }
    }
  }
}

.teamMember {
  text-align: center;

  @include media(portrait) {
    margin-top: var(--margin-lg);

    &:nth-child(odd) {
      margin-top: var(--margin-xxl);
    }
  }

  @include media(landscape) {
    margin-top: var(--margin-lg);

    &:nth-child(odd) {
      margin-top: var(--margin-xxl);
    }
  }

  @include media(desktopAll) {
    margin-top: var(--margin-lg);

    &:nth-child(odd) {
      margin-top: var(--margin-xxl);
    }
  }

  &.sliderView {
    width: 327px;
    margin: 0 24px;

    @include media(portrait) {
      width: 156px;
    }

    @include media(landscape) {
      width: 220px;
    }

    @include media(desktopAll) {
      width: 238px;
    }
  }

  &__photo {
    position: relative;
    height: 324px;
    max-width: 324px;
    margin: 0 auto;

    &.sliderView {
      aspect-ratio: 3 / 4;
      max-width: 327px;
      height: 100%;
  
      img {
        position: relative !important;
      }
    }

    @include media(portrait) {
      height: 405px;
    }

    @include media(landscape) {
      height: auto;
      max-width: none;
      aspect-ratio: 3 / 4;
    }

    @include media(desktopAll) {
      height: auto;
      max-width: none;
      aspect-ratio: 3 / 4;
    }

    img {
      object-fit: cover;
      object-position: top center;
    }
  }

  &__name {
    margin-top: 12px;
    margin-bottom: 4px;
    font-family: 'KharkivTone', sans-serif;
    font-size: 16px;
    line-height: 24px;

    &.sliderView {
      margin-top: 32px;
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 24px;
    }

    @include media(desktopAll) {
      margin-top: 32px;
      margin-bottom: 12px;
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__position {
    @include setProperty(color, var(--secondary-200), var(--secondary-400));
    font-size: 14px;
    line-height: 2;

    &.sliderView {
      font-size: 16px;
      line-height: normal;
      letter-spacing: 0.64px;
      font-weight: 300;
    }

    @include media(desktopAll) {
      font-size: 16px;
      line-height: normal;
      font-weight: 300;
      letter-spacing: 0.64px;
    }
  }
}